<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-form>
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Overenie bankového účtu</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text v-if="!user.verified_bank && user.bank_account">
            <p>Pre možnosť kandidatúry alebo pridávania komentárov je nutné aby prebehlo overenie pomocou bankového účtu.</p>
            <p>Pre overenie pošlite platbu vo výške minimálne <strong>{{ getSettings.hladina_volic }} €</strong> zo svojho účtu, ktorý ste uviedli pri registrácii ({{ user.bank_account }}).</p>

            <p>Platbu pošlite na bankový účet: <strong>SK4983300000004111441114</strong>, použite variabilný symbol: <strong>{{user.id}}</strong>.</p>
            <p>Následne sa platba automaticky do niekoľkých pracovných dní spáruje a Tvoj účet bude plne aktivovaný.</p>
          </v-card-text>
          <v-card-text v-else-if="!user.verified_bank && !user.bank_account">
            <p>Pre možnosť kandidatúry alebo pridávania komentárov je nutné aby prebehlo overenie pomocou bankového účtu.</p>
            <p>Pridaj svoj účet v <a href="/profile/0">profile</a> a následne sa vráť na túto stránku.</p>
          </v-card-text>

          <v-card-text v-else>
            Bankový účet kód bol overený <v-icon color="success">mdi-check</v-icon>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              to="/"
            >
              Zavrieť
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";

const apiService = new APIService();

export default {
  props: {
    source: String,
  },
  data() {
    return {
      drawer: null,

      user: {

      }
    };
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("settings", ["getSettings"]),
  },

  mounted: function () {
    this.getMe();
  },


  methods: {
    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
      });
    },
  }

};
</script>